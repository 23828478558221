import React, { useState } from "react";
import { apiRoutes, makeApiRequest } from "../../constants";
import { useAuth } from "util";
import { useCompany } from "util";

function TogglePublishButton({
  companyId,
  roleId,
  isPublished,
  onUpdate,
  disabled,
}) {
  const [isToggling, setIsToggling] = useState(false);

  const auth = useAuth();
  const { companyData } = useCompany();

  const handleTogglePublish = async () => {
    setIsToggling(true);

    try {
      // Send a PUT request to toggle the publish status
      const response = await makeApiRequest(
        "put",
        apiRoutes.togglePublishedStatus(companyId, roleId),
        auth.user,
        companyData.companyId,
        { isPublished: !isPublished }
      );

      if (response.status === 200) {
        // Update the publish status in the local state
        onUpdate();
      } else {
        console.error("Failed to toggle publish status");
      }
    } catch (error) {
      console.error("Error toggling publish status:", error);
    } finally {
      setIsToggling(false);
    }
  };

  return (
    <button
      className={`text-sm rounded-lg px-[20px] h-[42px] bg-[#3371ff] text-white font-medium  
      ${
        disabled
          ? "opacity-50 cursor-not-allowed"
          : "hover:bg-[#3370ffe4] cursor-pointer"
      }`}
      onClick={disabled ? null : handleTogglePublish}
      disabled={
        isToggling ||
        disabled ||
        auth.user.isVerified === "notVerified" ||
        auth.user.isVerified === "disabled"
      }
    >
      {isToggling ? "Toggling..." : isPublished ? "Unpublish" : "Publish"}
    </button>
  );
}

export default TogglePublishButton;
