import React, { useEffect, useState } from "react";
import { useAuth } from "../../../util";
import { redirectToBilling } from "../../../util";
import Spinner from "components/Spinner";

function SettingsBilling(props) {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth.user.planIsActive) {
      // If user has an active plan then
      // take them to Stripe billing
      redirectToBilling().catch((error) => {
        setLoading(false);
        props.onStatus({
          type: "error",
          message: error.message,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && (
        <div className="flex flex-col items-center gap-2 mt-[75px]">
          <Spinner size="25px" />
          <p>Redirecting to Stripe...</p>
        </div>
      )}
    </>
  );
}

export default SettingsBilling;
