import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ChevronRight } from "assets/icons/chevron-right.svg";

function HeroSection({ isMobileScreen }) {
  return (
    <div
      className={`flex flex-col items-center w-full mx-auto text-center ${
        isMobileScreen ? "mt-8  gap-4 px-[30px]" : "my-[60px] gap-4"
      }`}
    >
      <div className="p-[2px] rounded-full bg-gradient-to-r from-blue-400 via-blue-500 to-indigo-500 block-shadow">
        <div className="px-[16px] py-[8px] bg-white rounded-full flex gap-1 text-slate-700 text-[15px] font-medium">
          <p>Check out a sample landing page</p>
          <div className="flex items-center gap-2">
            <Link
              className="text-[#3370ff] flex items-center"
              to="/hiringspree/6694448beb88839d1d529c2d?platform=Hiring%20Spree"
            >
              here
              <ChevronRight className="stroke-[#3370ff] h-[18px] w-[18px]" />
            </Link>
          </div>
        </div>
      </div>
      <h1
        className={`tracking-tight font-bold text-slate-900 ${
          isMobileScreen
            ? "text-[36px] leading-[2.5rem]"
            : "text-[64px] leading-[4.5rem]"
        }`}
      >
        Your recruitment marketing co-pilot.
      </h1>
      <p
        className={`${
          isMobileScreen ? "text-[14px]" : "text-[18px]"
        } font-normal tracking-normal text-slate-700`}
      >
        Consolidate your candidate collateral, attract top talent, and let your
        employer value proposition shine.
      </p>
    </div>
  );
}

export default HeroSection;
