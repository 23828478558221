import React, { useEffect, useState } from "react";
import UserBlockWithActions from "./UserBlockWithActions";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";
import { apiRoutes } from "constants";
import { useAuth } from "util";
import { requireAuth, useCompany } from "../../../util";
import { makeApiRequest } from "constants";
import Modal from "components/Modal";
import StyledSelect from "components/StyledSelect";
import { permissionsOptions } from "constants";
import { ReactComponent as CircleCheck } from "assets/icons/circle-check.svg";
import Spinner from "components/Spinner";
import { useNavigate } from "../../../util";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
//
// TODO
// Prevent disabled users from being sent to the FE
//

function Team() {
  const auth = useAuth();
  const { companyData, userRole } = useCompany();
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [inviteSentTo, setInviteSentTo] = useState("");
  const [creatingUser, setIsCreatingUser] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Check user role and navigate if needed
    if (auth.user && userRole === "Employee") {
      // Navigate to "/pages"
      console.log("Navigating to /pages");
      navigate("/pages");
      return; // Stop further execution since the user is navigated
    }

    const fetchData = async () => {
      try {
        const userResponse = await makeApiRequest(
          "get",
          apiRoutes.getUsersByCompanyId,
          auth.user,
          companyData.companyId
        );
        setUsers(userResponse.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (auth.user) {
      fetchData();
    }
  }, []);

  const handleCreateUser = async () => {
    const data = {
      permissions: [
        {
          companyId: companyData.companyId,
          userRole: selectedPermissions,
        },
      ],
      email: newUserEmail,
    };
    setIsCreatingUser(true);
    try {
      const response = await makeApiRequest(
        "post",
        apiRoutes.inviteUser,
        auth.user,
        companyData.companyId,
        data
      );
      if (response.status === 200) {
        setIsCreatingUser(false);
        setInviteSentTo(newUserEmail);
        setInviteSent(true);
        setNewUserEmail("");
        setSelectedPermissions("");
        // Set inviteSent to false after 10 seconds
        setTimeout(() => {
          setInviteSent(false);
          setInviteSentTo("");
        }, 10000); // 10 seconds in milliseconds
      }
    } catch (error) {
      console.error("Error sending sign up link:", error);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setNewUserEmail("");
    setSelectedPermissions("");
  };

  return (
    <div className="pt-[40px] px-[75px] min-h-[100vh]">
      <h1 className="font-bold tracking-tight text-slate-900 text-[32px]">
        Team Members
      </h1>
      <p className="mt-2 text-sm">
        Manage your team members, update their account permissions, and invite
        new team members here.
      </p>

      {/* Subheader + Group Actions */}
      <div className="mt-[60px] mb-[20px] flex items-center">
        <p className="font-semibold tracking-tight text-slate-900 text-[24px]">
          All Users <span className="text-[#3371ff]">({users.length})</span>
        </p>
        <div className="ml-auto">
          <button
            className="bg-slate-900 text-white border-none font-medium text-[15px] pl-3 pr-5 py-2.5 rounded-lg flex flex-row gap-2 items-center"
            type="button"
            onClick={openModal} // Open the modal when the button is clicked
          >
            <Plus className="stroke-white" />
            <p>Add user</p>
          </button>
        </div>
      </div>

      <div className="pb-[40px] flex flex-col gap-[15px]">
        {users?.map((user, index) => (
          <UserBlockWithActions key={index} userData={user} />
        ))}
      </div>
      {/* Modal for adding a new user */}
      {isModalOpen ? (
        <Modal title="Add User" size="sm" closeModal={closeModal}>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col">
              <label className="text-[13px] font-medium tracking-tight text-slate-700">
                Invite new user
              </label>
              <input
                className="text-sm w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
                type="text"
                placeholder="name@example.com"
                value={newUserEmail}
                onChange={(e) => setNewUserEmail(e.target.value)}
              />
              <div className="mt-4">
                <StyledSelect
                  label="Assign Permissions"
                  options={permissionsOptions}
                  onChange={(selectedOption) =>
                    setSelectedPermissions(selectedOption.value)
                  }
                />
              </div>
            </div>

            {inviteSent ? (
              <div className="flex justify-between items-center px-4 py-3 border border-solid border-[#9AE7C2] rounded-md bg-[#EFFDF6]">
                <p className="text-sm font-semibold text-[#42946D]">
                  Invite Sent to {inviteSentTo}!
                </p>
                <CircleCheck className="stroke-[#42946D]" />
              </div>
            ) : null}
            <div className="flex items-center gap-4">
              <PrimaryActionButton
                text="Send Invite"
                type="button"
                disabled={newUserEmail === ""}
                action={handleCreateUser}
              />
              {creatingUser ? <Spinner size="25px" /> : null}
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  );
}

export default requireAuth(Team);
