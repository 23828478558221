import React from "react";
import EditorSection from "./EditorSection";
import EditorTitle from "./EditorTitle";
import CopyToClipboardButton from "components/Buttons/CopyToClipboardButton";
import TogglePublishButton from "components/Buttons/TogglePublishButton";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as Files } from "assets/icons/files.svg";
import { ReactComponent as EditIcon } from "assets/icons/pen-square.svg";
import { Link } from "react-router-dom";
import { IconButton } from "components/Buttons/IconButton";
import Tooltip from "components/Tooltip";
import CreateRoleFromTemplateButton from "components/Buttons/CreateRoleFromTemplateButton";
import { useCreateTemplateFromPage } from "hooks";

function EditorHeader({
  featuredRoleData,
  link,
  previewLink,
  isLoaded,
  togglePublish,
  openEditModal,
}) {
  const { createTemplateFromPage } = useCreateTemplateFromPage();

  const isPublishButtonDisabled =
    featuredRoleData.jobFeed && featuredRoleData.jobFeed?.length === 0;

  const createTemplate = () => {
    createTemplateFromPage(featuredRoleData);
  };

  return (
    <div className="col-span-2 p-[30px] block-shadow bg-white rounded-md">
      <div className="flex justify-between mb-3">
        <EditorTitle
          isPublished={featuredRoleData.isPublished}
          title={featuredRoleData.title}
          ctaType={featuredRoleData.ctaType}
          isTemplateRole={featuredRoleData.isTemplate}
        />

        {/* Button Div */}

        <div className="flex items-start">
          {featuredRoleData.isPublished ? (
            <Tooltip text="Copy Live Link">
              <CopyToClipboardButton textToCopy={link} />
            </Tooltip>
          ) : null}
          <Tooltip
            text={
              featuredRoleData.isTemplate ? "Preview Template" : "Preview Page"
            }
          >
            <Link
              className="stroke-slate-500 hover:stroke-[#3371ff] h-[42px] w-[42px] flex items-center justify-center rounded-xl  hover:bg-[#3371ff10]"
              to={previewLink}
            >
              <EyeIcon />
            </Link>
          </Tooltip>
          {!featuredRoleData.isTemplate ? (
            <Tooltip text="Share As Template">
              <IconButton onClick={createTemplate}>
                <Files className="stroke-slate-500 hover:stroke-[#3371ff]" />
              </IconButton>
            </Tooltip>
          ) : null}
          <Tooltip text="Edit Header Details">
            <IconButton onClick={openEditModal}>
              <EditIcon className="stroke-slate-500 hover:stroke-[#3371ff]" />
            </IconButton>
          </Tooltip>

          <div className="ml-2">
            {featuredRoleData.isTemplate ? (
              <CreateRoleFromTemplateButton roleData={featuredRoleData} />
            ) : (
              <TogglePublishButton
                companyId={featuredRoleData.companyId}
                roleId={featuredRoleData._id}
                isPublished={featuredRoleData.isPublished}
                onUpdate={togglePublish}
                disabled={isPublishButtonDisabled}
              />
            )}
          </div>
        </div>
      </div>

      <EditorSection isLoaded={isLoaded} roleDetails={featuredRoleData} />
    </div>
  );
}

export default EditorHeader;
