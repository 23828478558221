import React, { useState } from "react";
import ReauthModal from "../../../components/Auth/ReauthModal";
import { Link } from "react-router-dom";
import SettingsGeneral from "./SettingsGeneral";
import SettingsPassword from "./SettingsPassword";
import SettingsBilling from "./SettingsBilling";
import { useAuth, useCompany } from "../../../util";
import AccountUser from "pages/Account/ProfileSettings/AccountUser";

function SettingsSection(props) {
  const auth = useAuth();
  const { userRole } = useCompany();
  const [formAlert, setFormAlert] = useState(null);

  // State to control whether we show a re-authentication flow
  // Required by some security sensitive actions, such as changing password.
  const [reauthState, setReauthState] = useState({
    show: false,
  });

  const validSections = {
    general: true,
    password: true,
    billing: true,
  };

  const section = validSections[props.section] ? props.section : "general";

  // Handle status of type "success", "error", or "requires-recent-login"
  // We don't treat "requires-recent-login" as an error as we handle it
  // gracefully by taking the user through a re-authentication flow.
  const handleStatus = ({ type, message, callback }) => {
    if (type === "requires-recent-login") {
      // First clear any existing message
      setFormAlert(null);
      // Then update state to show re-authentication modal
      setReauthState({
        show: true,
        // Failed action to try again after reauth
        callback: callback,
      });
    } else {
      // Display message to user (type is success or error)
      setFormAlert({
        type: type,
        message: message,
      });
    }
  };

  return (
    <div className="py-[40px] px-[75px]">
      <h1 className="font-bold tracking-tight text-slate-900 text-[32px] mb-[40px]">
        Profile Settings
      </h1>
      <div className="mt-[20px] grid gap-[20px] grid-cols-[1fr_3fr]">
        <AccountUser />
        <section className="p-[30px] bg-white block-shadow rounded-[30px]">
          {reauthState.show && (
            <ReauthModal
              callback={reauthState.callback}
              provider={auth.user.providers[0]}
              onDone={() => setReauthState({ show: false })}
            />
          )}

          <div className="flex justify-center space-x-5">
            <Link
              to="/settings/general"
              className={
                "" + (section === "general" ? "font-semibold underline" : "")
              }
            >
              General
            </Link>
            <Link
              to="/settings/password"
              className={
                "" + (section === "password" ? "font-semibold underline" : "")
              }
            >
              Password
            </Link>
            <Link
              to="/settings/billing"
              className={
                "" + (section === "billing" ? "font-semibold underline" : "")
              }
            >
              Billing
            </Link>
          </div>
          <div>
            {formAlert && (
              <div
                className={
                  "mb-4" +
                  (formAlert.type === "error" ? " text-red-600" : "") +
                  (formAlert.type === "success" ? " text-green-600" : "")
                }
              >
                {formAlert.message}
              </div>
            )}

            {section === "general" && (
              <SettingsGeneral onStatus={handleStatus} />
            )}

            {section === "password" && (
              <SettingsPassword onStatus={handleStatus} />
            )}

            {section === "billing" && (
              <SettingsBilling onStatus={handleStatus} />
            )}
          </div>
        </section>
      </div>
    </div>
  );
}

export default SettingsSection;
