import { useCreateCompany } from "hooks/useCreateCompany";
import { Input } from "components/Input";
import Select from "components/Select";
import { TextArea } from "components/TextArea";
import {
  companySize,
  fundingStatus,
  fundingType,
  industries,
} from "constants/constants";
import { Controller } from "react-hook-form";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";
import SecondaryActionButton from "components/Buttons/SecondaryActionButton";

const CreateCompany = ({ toggleCreateCompany }) => {
  const { isSaving, control, handleSubmit } = useCreateCompany();
  return (
    <div className="w-[800px] p-[30px] block-shadow bg-white rounded-[30px]">
      <h2 className="font-semibold text-xl text-slate-700 tracking-tight mb-2">
        Add your company to Hiring Spree
      </h2>
      <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-[15px]">
        <Controller
          control={control}
          name="companyName"
          render={({ field }) => (
            <Input
              label="Company Name"
              value={field.value}
              setValue={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="companyId"
          render={({ field }) => (
            <Input
              label="Company ID"
              value={field.value}
              setValue={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="blurb"
          render={({ field }) => (
            <TextArea
              label="Description"
              value={field.value}
              setValue={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="companySize"
          render={({ field }) => (
            <Select
              placeholder="Company Size"
              value={field.value}
              options={companySize}
              onChange={field.onChange}
              label="Company Size"
            />
          )}
        />

        <Controller
          control={control}
          name="foundedDate"
          render={({ field }) => (
            <Input
              label="Year Founded"
              type="text"
              value={field.value}
              setValue={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="fundingStatus"
          render={({ field }) => (
            <Select
              placeholder="Funding Status"
              value={field.value}
              options={fundingStatus}
              onChange={(selectedOption) => field.onChange(selectedOption)}
              label="Funding Status"
            />
          )}
        />
        <Controller
          control={control}
          name="fundingType"
          render={({ field }) => (
            <Select
              placeholder="Funding Type"
              value={field.value}
              options={fundingType}
              onChange={field.onChange}
              label="Funding Type"
            />
          )}
        />

        <Controller
          control={control}
          name="homepageUrl"
          render={({ field }) => (
            <Input
              label="Homepage URL"
              type="text"
              value={field.value}
              setValue={field.onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="hqLocation"
          render={({ field }) => (
            <Input
              label="HQ Location"
              type="text"
              value={field.value}
              setValue={field.onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="industry"
          render={({ field }) => (
            <Select
              placement="top"
              placeholder="Industries"
              value={field.value}
              options={industries}
              onChange={field.onChange}
              multiple
              label="Industries"
            />
          )}
        />
        <div></div>
        <div className="flex justify-end mt-[30px] gap-[20px]">
          <SecondaryActionButton
            text="Company Search"
            action={toggleCreateCompany}
          />
          <PrimaryActionButton
            text={isSaving ? "..." : "Create Company"}
            buttonType="submit"
            disabled={isSaving}
          />
        </div>
      </form>
    </div>
  );
};

export default CreateCompany;
