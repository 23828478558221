import React from "react";
import Meta from "../../../components/Meta";
import SettingsSection from "./SettingsSection";
import { useRouter } from "../../../util";
import { requireAuth } from "../../../util";

function ProfileSettings(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Settings" />
      <SettingsSection
        section={router.query.section}
        key={router.query.section}
      />
    </>
  );
}

export default requireAuth(ProfileSettings);
