import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "util";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";
import SecondaryActionButton from "components/Buttons/SecondaryActionButton";
import CompanySearchResults from "./CompanySearchResults";
import { makeApiRequest } from "constants";
import { apiRoutes } from "constants";

function CompanySearch({ toggleCreateCompany }) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [companyId, setCompanyId] = useState(null);

  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const companySearch = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await makeApiRequest(
        "get",
        apiRoutes.companiesByName(searchText),
        auth.user
      );
      setSearchResults(response.data);
    } catch (error) {
      setSearchResults([]);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [searchText, auth.user]);

  useEffect(() => {
    if (searchText.trim() !== "") {
      companySearch();
    } else {
      // Reset search results when searchText is empty
      setSearchResults([]);
    }
  }, [searchText]);

  const handleUserInput = (e) => {
    setSearchText(e.target.value);
  };

  const attachToCompany = (companyId) => {
    setPending(true);

    let permissions = [
      {
        companyId: companyId,
        userRole: "Employee",
      },
    ];

    auth
      .updateProfile({ permissions })
      .then(() => {
        console.log({
          type: "success",
          message: "Company ID added to your account",
        });
      })
      .catch((error) => {
        console.log({
          type: "error",
          message: error.message,
        });
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <div className="w-[800px] p-[30px] block-shadow bg-white rounded-[30px]">
      <h2 className="font-semibold text-xl text-slate-700 tracking-tight mb-4">
        Add Yourself to a Company
      </h2>
      <div className="grid grid-cols-2 gap-[50px]">
        <div>
          <p className="text-sm mb-4">
            Welcome to Hiring Spree! The first order of business is to add
            yourself to your company, or create a new company if you're the
            first Hiring Spree user. We review all accounts to make sure you,
            and any team members added, meet our standards for verification on
            the platform.
          </p>
          <div className="flex flex-col">
            <label
              htmlFor="companyName"
              className="text-[13px] font-medium tracking-tight text-slate-700"
            >
              Search for your company
            </label>
            <input
              className="text-sm w-full mt-1 py-2 px-3 bg-slate-100 rounded-md"
              type="text"
              name="companyName"
              onChange={handleUserInput}
              value={searchText}
            />
          </div>
        </div>
        <div className="h-[200px] overflow-scroll flex flex-col gap-3">
          <CompanySearchResults
            searchResults={searchResults}
            setCompanyId={setCompanyId}
            searchText={searchText}
            loading={loading}
            error={error}
            companyId={companyId}
          />
        </div>
      </div>
      <div className="flex justify-end mt-[30px] gap-[20px]">
        <SecondaryActionButton
          text="Not Seeing Your Company?"
          action={toggleCreateCompany}
        />
        <PrimaryActionButton
          text={pending ? "..." : "Select Company"}
          type="button"
          disabled={pending || companyId === null}
          action={() => attachToCompany(companyId)}
        />
      </div>
    </div>
  );
}

export default CompanySearch;
