import React, { useState } from "react";
import { useAuth, requireAuth } from "util";
import { v4 as uuidv4 } from "uuid";
import SharedComponent from "./SharedComponent";
import AddComponentModal from "pages/Account/Home/components/AddComponentModal";
import TextBlockInput from "pages/Account/Home/BlockInputs/TextBlockInput";
import ImageBlockInput from "pages/Account/Home/BlockInputs/ImageBlockInputs";
import VideoBlockInput from "pages/Account/Home/BlockInputs/VideoBlockInputs";
import EmbedBlockInput from "pages/Account/Home/BlockInputs/EmbedBlockInputs";
import PersonnelBlockInput from "pages/Account/Home/BlockInputs/PersonnelBlockInputs";
import { apiRoutes, makeApiRequest } from "constants/apiRoutes";
import { useCompany } from "util";
import PrimaryActionButton from "components/Buttons/PrimaryActionButton";

function SharedComponents() {
  const auth = useAuth();
  const { companyData, userRole, fetchCompanyData } = useCompany();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedComponentType, setSelectedComponentType] = useState(null);
  const [editedComponent, setEditedComponent] = useState(null);

  const companyComponents = companyData.sharedComponents?.filter(
    (component) => !component.isArchived
  );

  const updateCompanyData = async (data) => {
    const response = await makeApiRequest(
      "put",
      apiRoutes.companyDataById(companyData.companyId),
      auth.user,
      companyData.companyId,
      data
    );

    // If the operation is successful, refetch the company data to update the UI
    if (response.status < 300) {
      fetchCompanyData();
    }

    // The response will propogate up to the individual create/edit/delete modals, which handle error catching
    return response;
  };

  const handleCreateSharedComponent = async (blockData) => {
    const blockDataWithId = { ...blockData, id: uuidv4() };
    if (companyComponents) {
      companyData.sharedComponents.push(blockDataWithId);
    } else {
      companyData.sharedComponents = [blockDataWithId];
    }

    return await updateCompanyData(companyData);
  };

  const handleEditSharedComponent = async (blockData) => {
    const blockIndex = companyData.sharedComponents.findIndex(
      (component) => component.id === blockData.id
    );
    companyData.sharedComponents[blockIndex] = blockData;

    return await updateCompanyData(companyData);
  };

  // Deleting a shared component doesn't actually "delete" it, but rather archives it to
  // preserve content on existing pages that use it.
  const handleDeleteSharedComponent = async (id) => {
    const blockIndex = companyData.sharedComponents.findIndex(
      (component) => component.id === id
    );
    companyData.sharedComponents[blockIndex] = {
      ...companyData.sharedComponents[blockIndex],
      isArchived: true,
    };

    return await updateCompanyData(companyData);
  };

  const closeModal = () => {
    setEditedComponent(null);
    setSelectedComponentType(null);
    setIsModalOpen(false);
  };

  return (
    <div className="px-[75px] pt-[40px] min-h-[100vh]">
      <div className="flex justify-between items-center">
        <h1 className="font-bold tracking-tight text-slate-900 text-[32px]">
          Shared Components
        </h1>
        {userRole === "Employee" ? null : (
          <PrimaryActionButton
            text="Create Component"
            type="button"
            action={() => setIsModalOpen(true)}
          />
        )}
      </div>
      <div>
        <p className="mt-2 max-w-[700px]">
          Edit once, update everywhere. Effortlessly maintain a consistent look
          across your organizations pages by creating reusable elements. Take
          control of your content with quick, real-time adjustments.
        </p>
        {/* Container for Blocks */}
        <div className="mt-[30px] grid grid-cols-2 gap-[15px]">
          {!companyComponents || companyComponents?.length === 0 ? (
            <div>
              <p className="text-sm">
                Shared components can be easily reused in any page your team
                members create. Whenever you update a company component here,
                the those edits will automatically sync on each page where it's
                used.
              </p>
              <p className="text-sm mt-4">
                Click the{" "}
                <span
                  className="font-semibold text-[#3371ff] cursor-pointer"
                  onClick={() => setIsModalOpen(true)}
                >
                  Create Component
                </span>{" "}
                button above to create your first component!
              </p>
            </div>
          ) : (
            companyComponents.map((component) => (
              <SharedComponent
                component={component}
                onEditComponent={() => {
                  setEditedComponent(component);
                  setSelectedComponentType(
                    [
                      "linkedin-post",
                      "twitter-post",
                      "twitter-timeline",
                    ].includes(component.blockType)
                      ? "embed"
                      : component.blockType
                  );
                  setIsModalOpen(true);
                }}
                onDeleteComponent={(id) => handleDeleteSharedComponent(id)}
              />
            ))
          )}
        </div>
      </div>
      {isModalOpen ? (
        selectedComponentType ? (
          <>
            {/* Render the appropriate component form based on selectedComponent */}
            {selectedComponentType === "text" && (
              <TextBlockInput
                close={closeModal}
                setSelectedComponent={setSelectedComponentType}
                isEditing={editedComponent !== null}
                existingContent={editedComponent ?? undefined}
                refetch={() => {}}
                handleCreateSharedComponent={handleCreateSharedComponent}
                handleEditSharedComponent={handleEditSharedComponent}
                isTeamsPage
              />
            )}
            {selectedComponentType === "image" && (
              <ImageBlockInput
                close={closeModal}
                setSelectedComponent={setSelectedComponentType}
                isEditing={editedComponent !== null}
                existingContent={editedComponent ?? undefined}
                refetch={() => {}}
                handleCreateSharedComponent={handleCreateSharedComponent}
                handleEditSharedComponent={handleEditSharedComponent}
                isTeamsPage
              />
            )}
            {selectedComponentType === "video" && (
              <VideoBlockInput
                close={closeModal}
                closeNoRefresh={closeModal}
                setSelectedComponent={setSelectedComponentType}
                isEditing={editedComponent !== null}
                existingContent={editedComponent ?? undefined}
                refetch={() => {}}
                handleCreateSharedComponent={handleCreateSharedComponent}
                handleEditSharedComponent={handleEditSharedComponent}
                isTeamsPage
              />
            )}
            {selectedComponentType === "embed" && (
              <EmbedBlockInput
                close={closeModal}
                setSelectedComponent={setSelectedComponentType}
                isEditing={editedComponent !== null}
                existingContent={editedComponent ?? undefined}
                refetch={() => {}}
                handleCreateSharedComponent={handleCreateSharedComponent}
                handleEditSharedComponent={handleEditSharedComponent}
                isTeamsPage
              />
            )}
            {selectedComponentType === "personnel" && (
              <PersonnelBlockInput
                close={closeModal}
                closeNoRefresh={closeModal}
                setSelectedComponent={setSelectedComponentType}
                isEditing={editedComponent !== null}
                existingContent={editedComponent ?? undefined}
                refetch={() => {}}
                handleCreateSharedComponent={handleCreateSharedComponent}
                handleEditSharedComponent={handleEditSharedComponent}
                isTeamsPage
              />
            )}
            {/* Add other component forms as needed */}
          </>
        ) : (
          <AddComponentModal
            close={closeModal}
            renderComponentForm={(componentType) =>
              setSelectedComponentType(componentType)
            }
            closeWithRefresh={closeModal}
            isTeamsPage
          />
        )
      ) : null}
    </div>
  );
}

export default requireAuth(SharedComponents);
