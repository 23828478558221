import React from "react";

function AddToSlack() {
  const linkStyle = {
    alignItems: "center",
    color: "#000",
    backgroundColor: "#fff",
    display: "inline-flex",
    fontFamily: "Lato, sans-serif",
    fontSize: "14px",
    fontWeight: "600",
    justifyContent: "center",
    textDecoration: "none",
  };

  const svgStyle = {
    height: "20px",
    width: "20px",
    marginRight: "12px",
  };

  return (
    <a
      href="https://slack.com/oauth/v2/authorize?scope=chat%3Awrite%2Cim%3Awrite&amp;user_scope=&amp;redirect_uri=https%3A%2F%2Fhiringspree.io%2Fsettings%2Fslack%2Fauth&amp;client_id=5955245671829.6375987990454"
      style={linkStyle}
      className="block-shadow rounded-md px-5 py-3"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={svgStyle}
        viewBox="0 0 122.8 122.8"
      >
        <path
          d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
          fill="#e01e5a"
        ></path>
        <path
          d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
          fill="#36c5f0"
        ></path>
        <path
          d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
          fill="#2eb67d"
        ></path>
        <path
          d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
          fill="#ecb22e"
        ></path>
      </svg>
      Add to Slack
    </a>
  );
}

export default AddToSlack;
