import React from "react";
import { ReactComponent as CircleCheck } from "assets/icons/circle-check.svg";

function CompanySearchResults({
  searchResults,
  setCompanyId,
  searchText,
  loading,
  error,
  companyId,
}) {
  if (loading) {
    return <p>Loading...</p>;
  }

  if (searchResults.length === 0 && searchText === "") {
    return <p>Search for your company</p>;
  }

  if (searchResults.length === 0) {
    return <p>Not seeing your company? Add it below!</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <>
      {searchResults.map((item, i) => (
        <button
          key={i}
          type="button"
          onClick={() =>
            setCompanyId(companyId === item.companyId ? null : item.companyId)
          }
          className={`flex items-center justify-between p-3 border-1 ${
            companyId === item.companyId
              ? "border-[#3371FF]"
              : "border-indigo-100"
          } hover:border-[#3371FF] border-solid rounded-xl`}
        >
          <div className="flex items-center gap-[20px]">
            <img
              src={item.companyLogoUrl}
              className="w-[30px] h-[30px] rounded-full block-shadow"
            />
            {item.companyName}
          </div>

          {companyId === item.companyId ? (
            <CircleCheck className="stroke-[#3371FF]" />
          ) : null}
        </button>
      ))}
    </>
  );
}

export default CompanySearchResults;
