import React from "react";
import { useCompany } from "util";
import { useAuth } from "util/auth";
import { makeApiRequest, apiRoutes } from "constants";
import AddToSlack from "./AddToSlack";

function AccountUser() {
  const auth = useAuth();

  const { companyData, userRole } = useCompany();

  const handleSaveImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      const response = await makeApiRequest(
        "post",
        apiRoutes.profilePicture(auth.user.id),
        auth.user,
        companyData.companyId,
        formData
      );
      if (response.status === 201) {
        console.log("Profile picture changed");
      }
    } catch (error) {
      console.error(error, "error");
    }
  };

  function onError(e) {
    e.target.src =
      "https://hs-icons.s3.us-east-2.amazonaws.com/hs_logo_blue.png";
  }
  return (
    <div>
      <div className="block-shadow bg-white p-[30px] sticky top-0 rounded-[30px]">
        <div className="flex items-center justify-between">
          <img
            className="h-[70px] w-[70px] rounded-full bg-white block-shadow"
            src={auth.user.userImage}
            onError={(e) => onError(e)}
            alt="logo"
          />
          <div className="flex flex-col mr-2">
            <label
              htmlFor="logo-upload"
              className="text-sm cursor-pointer text-[#3371ff]"
            >
              Change Picture
            </label>
            <input
              id="logo-upload"
              type="file"
              className="hidden"
              onChange={(e) => handleSaveImage(e.target.files[0])}
            />
          </div>
        </div>
        <div className="mt-3">
          <h2 className="font-bold tracking-tight text-gray-900 text-lg">
            {auth.user?.name}
          </h2>
        </div>
        <div className="mt-2 text-sm">
          You are signed in as <strong>{auth.user?.email}</strong>.
        </div>
        <div className="mt-2 text-sm">
          You have <strong>{userRole}</strong> permissions.
        </div>
        <div className="mt-2 text-sm">
          You are a <strong>{auth.user?.userTitle}</strong>.
        </div>
        <div className="mt-2 text-sm">
          You are attached to the <strong>{companyData.companyId}</strong>{" "}
          company ID.
        </div>
        {auth.user?.stripeSubscriptionId && (
          <>
            <div className="mt-2 text-sm">
              You are subscribed to the{" "}
              <strong>{auth.user?.planId} plan</strong>.
            </div>
            <div className="mt-2 text-sm">
              Your plan status is{" "}
              <strong className="text-blue-600">
                {auth.user?.stripeSubscriptionStatus}
              </strong>
              .
            </div>
          </>
        )}

        <div className="mt-2 text-sm">
          Your email is{" "}
          <strong>
            {auth.user?.emailVerified ? "verified" : "unverified"}
          </strong>
          .
        </div>

        <div className="mt-2 text-sm">
          Your account is <strong>{auth.user?.isVerified}</strong>.
        </div>

        {auth.user.featureFlag === "beta" ? (
          <div className="mt-2">
            <AddToSlack />
          </div>
        ) : null}

        {/* I'm sure we'll need this at some point 
        when we do different tiers and self serve, 
        but for now we can cancel it out */}
        {/* {!auth.user?.stripeSubscriptionId && (
          <div className="mt-2 text-sm">
            You can signup for a plan in{" "}
            <Link to="/pricing" className="text-blue-600">
              pricing
            </Link>
            .
          </div>
        )} */}
      </div>
    </div>
  );
}

export default AccountUser;
